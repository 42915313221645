@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Epilogue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Epilogue', sans-serif;
}

@media only screen and (max-width: 768px) {
.rooms_roomcard1__VAryK {
  margin-right: 0 !important;
}
}