.roomtext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #000;
  margin-bottom: 24px;
  text-align: center;
  text-decoration: none;
}

.roombg {
  background-image: url(../assets/images/room-bg-image.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.roomcardphone {
  display: none;
}

.roomcard {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 100px;
}

.roomcard1 {
  background-image: url(../assets/images/executive\ room.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 15px;
  width: 350px;
  height: 420px;
}

.roomcard2 {
  background-image: url(../assets/images/Premium\ room.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 15px;
  width: 350px;
  height: 420px;
}

.roomcard3 {
  background-image: url(../assets/images/standard\ room.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 15px;
  width: 350px;
  height: 420px;
}

.cardtitle {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #414042;
  text-decoration: none;
  text-transform: capitalize;
  margin-top: 320px;
}

.cardtext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #414042;
  text-decoration: none;
}

.viewlink {
  text-decoration: none;
  color: #414042;
}

.roomimage {
  width: 100%;
  height: 100vh;
}

.row2img {
  width: 100%;
  height: 400px;
  padding: 10px 0 0;
}

.col1 {
  padding: 0;
}

.col2 {
  padding-left: 7px;
}

.col3 {
  height: 620px;
}

.col3img {
  width: 100%;
  height: 720px;
}

.viewtext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  text-decoration: none;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  visibility: hidden;
}

.facilitiesdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.bigtext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #000;
  margin-top: 24px;
  margin-bottom: 16px;
}

.smalltext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000;
}

@media only screen and (max-width: 1200px) {
  .roomtext {
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .roombg {
    height: 500px;
  }

  .roomcard {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 80px;
  }

  .roomcard1 {
    margin-right: 12px;
    width: 300px;
    height: 350px;
  }

  .roomcard2 {
    margin-right: 12px;
    width: 300px;
    height: 350px;
  }

  .roomcard3 {
    margin-right: 12px;
    width: 300px;
    height: 350px;
  }

  .cardtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin-top: 250px;
  }

  .cardtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }

  .roomimage {
    width: 100%;
    height: 100vh;
  }

  .row1img {
    height: 270px;
    padding-right: 7px;
    width: 210px;
  }

  .row1imgg {
    height: 270px;
    padding-right: 7px;
    width: 210px;
  }

  .row2img {
    width: 100%;
    height: 300px;
    padding: 10px 0 0;
  }

  .col1 {
    padding: 0;
  }

  .col2 {
    padding-left: 7px;
    padding-right: 7px;
  }

  .col3img {
    width: 100%;
    height: 570px;
  }

  .viewtext {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 15px;
  }

  .facilitiesdiv {
    height: 250px;
  }

  .bigtext {
    font-size: 16px;
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 14px;
  }

  .smalltext {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000;
  }
}

@media only screen and (max-width: 768px) {
  .roombg {
    display: none;
  }

  .roomtext {
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .roomcardphone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-right: 0;
  }
  .roomcard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-right: 0;
  }

  .roomcard1 {
    width: 600px;
    height: 350px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .roomcard2 {
    width: 600px;
    height: 350px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .roomcard3 {
    width: 600px;
    height: 350px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .cardtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin-top: 250px;
  }

  .cardtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }

  .roomimage {
    width: 100%;
  }

  .row1img {
    margin-bottom: 0px;
    width: 100%;
  }

  .row1imgg {
    padding-right: 0;
    margin-bottom: 0px;
    width: 100%;
  }

  .row2img {
    padding: 0;
    margin-bottom: 9px;
    height: 400px;
  }

  .col1 {
    padding: 0;
    margin-bottom: 10px;
  }
  .col2 {
    padding: 0;
    margin-bottom: 10px;
  }
  .col3 {
    padding: 0;
    margin-bottom: 0px;
    height: 540px;
  }

  .viewtext {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin-top: 35px;
    margin-bottom: 15px;
  }

  .facilitiesdiv {
    height: 250px;
  }

  .bigtext {
    font-size: 16px;
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 14px;
  }

  .smalltext {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000;
  }
}

@media only screen and (max-width: 450px) {
  .roomtext {
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .roomcard {
    display: flex;
    align-items: center;
    justify-content: right;
    margin: auto;
    margin-right: 0;
  }

  .roomcard1 {
    width: 300px;
    height: 350px;
    margin-right: 0;
  }

  .roomcard2 {
    width: 300px;
    height: 350px;
    margin-right: 0;
  }

  .roomcard3 {
    width: 300px;
    height: 350px;
    margin-right: 0;
  }

  .cardtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin-top: 250px;
  }

  .cardtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }

  .roomimage {
    width: 100%;
    height: 100vh;
  }

  .row1img {
    width: 100%;
    padding: 0 0 10px 0;
  }
  .row2img {
    width: 100%;
    height: 270px;
    padding: 0 0 10px 0;
  }
  .col1 {
    margin: auto;
    height: 270px;
    width: 100%;
  }
  .col2 {
    width: 100%;
    padding: 0;
  }

  .col3 {
    width: 100%;
    padding: 0;
    height: 270px;
  }

  .col3img {
    width: 100%;
    padding: 0;
    height: 270px;
  }

  .viewtext {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .facilitiesdiv {
    height: 250px;
  }

  .bigtext {
    font-size: 16px;
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 14px;
  }

  .smalltext {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000;
  }
}
