.buttondiv {
  display: flex;
  justify-content: left;
  padding-top: 55px;
  padding-bottom: 55px;
  margin-bottom: 20px;
  border-bottom: 4px solid #231f20;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #414042;
}

.detailsdiv {
  padding: 15px;
  border: 1px solid #bcbec0;
}

.spantext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #414042;
}

.icons {
  margin: 0 15px;
}

.buttondetails {
  width: 175px;
  height: 55px;
  background-color: #fbb040;
  border: 2px solid #fbb040;
  color: #231f20;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkdetails {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errordiv {
  background-color: #eb5757;
  color: #fff;
  height: 55px;
  padding-left: 15px;
  margin: 0 0 20px;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
}

.requestdiv {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #231f20;
}

.requestbutton {
  margin-top: 32px;
  margin-bottom: 55px;
  border: 2px solid #fbb040;
  background-color: #fbb040;
  color: #231f20;
  width: 145px;
  height: 40px;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.arrivaldiv {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #231f20;
}

.datebutton {
  margin-top: 33px;
  margin-right: 24px;
  border: 2px solid #231f20;
  background-color: #231f20;
  color: #fff;
  width: 245px;
  height: 50px;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.daysbutton {
  border: 1px solid #bcbec0;
  background-color: #fff;
  color: #231f20;
  width: 245px;
  height: 50px;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.cardcontainer {
  width: 530px;
  border: none;
  margin-top: 40px;
}

.cardtitle {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 40px;
}

.peoplediv {
  margin: 10px 0;
}

.peopletext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.pricediv {
  display: flex;
}

.price {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: #fbb040;
  margin-right: 55px;
}

.selectbutton {
  border: 2px solid #231f20;
  background-color: #231f20;
  color: #fff;
  width: 200px;
  height: 40px;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.roomdiv {
  display: flex;
  height: 180px;
  border: 1px solid #bcbec0;
  margin-top: 30px;
  width: 100%;
}

.guestdiv {
  display: flex;
  height: 180px;
  border: 1px solid #bcbec0;
  margin-top: 30px;
  width: 100%;
}

.roomdetailsdiv {
  padding: 24px;
}

.smallprice {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #fbb040;
  margin-left: 15px;
}

.roomtypediv {
  display: flex;
}

.changeroomdiv {
  display: flex;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  border-top: 4px solid #000;
  margin-top: 50px;
}

.containerr {
  border-top: 4px solid #000;
  margin-top: 50px;
}

.signuptext {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 37px;
  color: #414042;
  padding-top: 50px;
  padding-bottom: 25px;
}

.detailstext {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #414042;
  padding-bottom: 25px;
}

.inputcheck {
  width: 550px;
  height: 40px;
  color: #000;
  border: 1px solid #bcbec0;
  border-radius: 0;
  margin-bottom: 16px;
}

.buttoncheck {
  width: 550px;
  height: 40px;
  color: #231f20;
  background-color: #fbb040;
  border: 2px solid #fbb040;
  border-radius: 0;
  margin: 10px 0 20px;
}

.buttonradio {
  width: 150px;
  height: 40px;
  color: #231f20;
  background-color: #fbb040;
  border: 2px solid #fbb040;
  border-radius: 0;
  margin: 10px 0 20px;
}

.guestdiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.buttonsdiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.guestinfodiv {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.infodiv {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}
.flexdiv {
  display: flex;
  justify-content: right;
}

.labeltext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #414042;
}

.labelltext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #414042;
  margin-top: 24px;
}

.infotext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #414042;
  margin-top: 16px;
}

.images {
  width: 500px;
}

.bookingmodal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  margin: auto;
}

.bookingform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 310px;
  margin: auto;
}

.bookingtext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 16px;
  color: #000;
}

.smallbookingtext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 24px;
  color: #000;
}

.modalinput {
  width: 310px;
  height: 40px;
  border-radius: 0;
  margin-bottom: 16px;
  border: 1px solid #bcbec0;
}

.modalbutton {
  width: 310px;
  height: 40px;
  background-color: #fbb040;
  color: #231f20;
  border: none;
  border-radius: 0;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 8px 0 35px;
}

@media only screen and (max-width: 1200px) {
  .images {
    width: 400px;
  }

  .cardcontainer {
    width: 400px;
  }
}

@media only screen and (max-width: 768px) {
  .buttondiv {
    padding-top: 35px;
    padding-bottom: 35px;
    margin-bottom: 15px;
  }
  .detailsdiv {
    padding: 10px;
    width: 300px;
  }

  .buttondetails {
    width: 120px;
    height: 45px;
    font-size: 12px;
    line-height: 18px;
  }
  .spantext {
    font-size: 12px;
    line-height: 18px;
  }

  .cardtitle {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .peopletext {
    font-size: 12px;
    line-height: 18px;
  }

  .labeltext {
    font-size: 10px;
    line-height: 10px;
  }

  .infotext {
    font-size: 12px;
    line-height: 18px;
  }

  .icons {
    height: 18px;
    margin: 0 10px;
  }

  .smallprice {
    font-size: 20px;
    line-height: 20px;
    color: #fbb040;
    margin-left: 15px;
  }

  .price {
    font-size: 30px;
    line-height: 30px;
    margin-right: 35px;
  }

  .selectbutton {
    width: 150px;
    height: 35px;
    font-size: 12px;
    line-height: 18px;
  }

  .images {
    width: 500px;
  }

  .cardcontainer {
    width: 500px;
  }

  .roomdetailsdiv {
    padding: 18px;
  }

  .signuptext {
    font-size: 30px;
    line-height: 31px;
    padding-top: 70px;
    padding-bottom: 20px;
  }

  .detailstext {
    padding-bottom: 20px;
  }

  .inputcheck {
    width: 450px;
    margin-bottom: 16px;
  }

  .buttoncheck {
    width: 450px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .buttondiv {
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 10px;
  }

  .detailsdiv {
    padding: 10px;
    width: 245px;
  }

  .buttondetails {
    width: 90px;
    height: 45px;
    font-size: 10px;
    line-height: 12px;
  }

  .spantext {
    font-size: 10px;
    line-height: 12px;
  }

  .peopletext {
    font-size: 10px;
    line-height: 12px;
  }

  .labeltext {
    font-size: 8px;
    line-height: 8px;
  }

  .infotext {
    font-size: 10px;
    line-height: 12px;
  }

  .icons {
    height: 14px;
    margin: 0 8px;
  }

  .price {
    font-size: 25px;
    line-height: 25px;
    margin-right: 25px;
  }

  .selectbutton {
    width: 100px;
    height: 30px;
    font-size: 10px;
    line-height: 12px;
  }

  .images {
    width: 300px;
  }

  .cardcontainer {
    width: 300px;
  }

  .roomdiv {
    height: 350px;
    margin-top: 30px;
    flex-direction: column;
  }

  .guestdiv {
    height: 150px;
  }

  .roomimage {
    height: 180px;
    width: 102.5%;
  }

  .signuptext {
    font-size: 25px;
    line-height: 26px;
    padding-top: 40px;
    padding-bottom: 15px;
  }

  .detailstext {
    font-size: 10px;
    line-height: 10px;
    padding-bottom: 15px;
  }

  .inputcheck {
    width: 350px;
    margin-bottom: 12px;
  }

  .buttoncheck {
    width: 350px;
  }
}
@media only screen and (max-width: 350px) {
  .detailsdiv {
    padding: 8px;
    width: 220px;
  }

  .buttondetails {
    width: 80px;
    height: 40px;
    font-size: 8px;
    line-height: 10px;
  }

  .spantext {
    font-size: 8px;
    line-height: 10px;
  }

  .icons {
    height: 12px;
    margin: 0 7px;
  }

  .images {
    width: 280px;
  }

  .cardcontainer {
    width: 280px;
  }

  .roomdiv {
    height: 370px;
  }

  .inputcheck {
    width: 280px;
    margin-bottom: 10px;
  }

  .buttoncheck {
    width: 280px;
  }
}
