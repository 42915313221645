.smalltable {
  width: 385px;
  padding: 50px 0;
}

.maintable {
  width: 100%;
}

.tablehead {
  font-family: "Epilogue", sans-serif;
  background-color: #231f20;
  color: #fff;
  height: 40px;
  padding: 10px;
  font-size: 14px;
}

.tabledata {
  font-family: "Epilogue", sans-serif;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  border: none;
  box-shadow: 0px 1px 1px rgba(54, 54, 54, 0.1);
}
.bigtable {
  width: 100%;
  padding: 30px 0;
}

.bookingbutton {
  width: 245px;
  height: 40px;
  background-color: #fbb040;
  color: #231f20;
  border: none;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-left: 25px;
}

.searchdiv {
  display: flex;
  justify-content: right;
}

.searchinput {
  width: 270px;
  height: 40px;
  border: 1px solid #414042;
  padding-left: 10px;
}

.checkinbutton {
  width: 100px;
  height: 40px;
  background-color: #56c568;
  color: #fff;
  border: 2px solid #56c568;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 3px;
}

.checkoutbutton {
  width: 100px;
  height: 40px;
  background-color: #eb5757;
  color: #fff;
  border: 2px solid #eb5757;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 15px;
  padding: 3px;
}

.bookingmodal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  margin: auto;
}

.checkinmodal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  margin: auto;
}

.bookingform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 310px;
  margin: auto;
}

.bookingtext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 16px;
  color: #000;
}

.smallbookingtext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 24px;
  color: #000;
}

.modalinput {
  width: 310px;
  height: 40px;
  border-radius: 0;
  margin-bottom: 16px;
  border: 1px solid #bcbec0;
}

.modalbutton {
  width: 310px;
  height: 40px;
  background-color: #fbb040;
  color: #231f20;
  border: none;
  border-radius: 0;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 8px 0 35px;
}
