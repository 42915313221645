* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* a {
  pointer-events: none;
} */

a :hover {
  text-decoration: none;
}

.root {
  padding-left: 100px;
  padding-right: 100px;
}

a.text-light.nav-link.active {
  border-bottom: 4px solid #fbb040;
}

.anchor {
  text-decoration: none;
}

.anchor :hover {
  text-decoration: none !important;
}

.sectiondiv {
  padding-top: 90px;
  text-decoration: none;
}

@media only screen and (max-width: 1200px) {
  .root {
    padding-left: 90px;
    padding-right: 90px;
  }

  .sectiondiv {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 992px) {
  .root {
    padding-left: 80px;
    padding-right: 80px;
  }

  .sectiondiv {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .root {
    padding-left: 50px;
    padding-right: 50px;
  }

  .sectiondiv {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .root {
    padding-left: 40px;
    padding-right: 40px;
  }

  .sectiondiv {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .root {
    padding-left: 20px;
    padding-right: 20px;
  }

  .sectiondiv {
    padding-top: 20px;
  }

  div.active.carousel-item {
    width: 300px;
  }

  div.carousel-inner {
    width: 300px;
  }

  div.carousel.slide {
    width: 300px;
  }

  .carousel-item {
    width: 300px;
  }
}
