.abouttext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #000;
  margin-bottom: 24px;
}

.smalltext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  text-decoration: none;
  margin-bottom: 20px;
  text-align: justify;
}

.learntext {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #fbb040;
  text-decoration: none;
  margin-bottom: 20px;
  text-align: justify;
}

@media only screen and (max-width: 1200px) {
  .abouttext {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 22px;
  }

  .smalltext {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 18px;
  }

  .learntext {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .abouttext {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 22px;
    margin-top: 30px;
  }

  .smalltext {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 18px;
  }

  .learntext {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 18px;
  }

  .carouseldiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 450px) {
  .carouseldiv {
    display: block;
  }
}
