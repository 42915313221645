.herobg {
  background-image: url(../assets/images/background-image.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

/****************** Styling for Header.js ***********************/
.head {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
}

.addresstext {
  color: #fff;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.addresstexts {
  color: #000;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.icondiv {
  display: flex;
}

.heading {
  padding-right: 35px;
}
.heading:last-child {
  padding-right: 0;
}

.headlink {
  cursor: pointer;
}

.logo {
  cursor: pointer;
}

.navLinks {
  text-decoration: none;
  color: #fff;
  font-family: "Epilogue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-right: 24px;
}
/****************** STYLING FOR HERO TEXT********************/

.herotext {
  font-family: "Epilogue";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 54px;
  color: #fbb040;
  padding-top: 150px;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.herosmall {
  font-family: "Epilogue";
  letter-spacing: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 54px;
  color: #fff;
}

/******************************* End of Styling Hero text***************/

/*********************************** Dropdown style ***************************/

.db_head {
  margin-top: 180px;
  display: flex;
  justify-content: space-between;
}

.db {
  font-size: 7px;
  padding-top: -10px;
}

.db_form {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.db_input {
  width: 24%;
  height: 40px;
  color: #000;
  border: 1px solid #bcbec0;
  border-radius: 0;
  margin-bottom: 16px;
  margin-right: 15px;
  padding: 7px;
}

.dateinput {
  width: 24%;
  height: 40px;
  color: #000;
  border-radius: 0;
  margin-bottom: 16px;
  margin-right: 15px;
  padding: 7px;
}

.dateinputt {
  width: 300px;
  height: 40px !important;
  color: #000 !important;
  border-radius: 0 !important;
  margin-top: -7px;
  margin-bottom: 16px;
  padding: 7px;
}

.dbinput {
  width: 280px;
  height: 40px;
  color: #000;
  border: 1px solid #bcbec0;
  border-radius: 0;
  margin-bottom: 16px;
  padding: 7px;
}

.dbbtn {
  width: 24%;
  height: 40px;
  color: #231f20;
  background-color: #fbb040;
  border: 2px solid #fbb040;
  border-radius: 0;
}

.btn {
  width: 200px;
  height: 40px;
  color: #231f20;
  background-color: #fbb040;
  border: 2px solid #fbb040;
  border-radius: 0;
}

.btnn {
  width: 280px;
  height: 40px;
  color: #231f20;
  background-color: #fbb040;
  border: 2px solid #fbb040;
  border-radius: 0;
}

.phonebtn {
  display: none;
}

.bookingmodal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 400px;
  margin: auto;
}

.bookingform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 310px;
  margin: auto;
}

/*********************************** Dropdown style ***************************/

.logoutdiv {
  display: flex;
  justify-content: space-between;
}

.buttoncheck {
  width: 150px;
  height: 40px;
  color: #fff;
  background-color: #eb5757;
  border: 2px solid #eb5757;
  border-radius: 0;
  margin-top: 10px;
}

@media only screen and (max-width: 1200px) {
  .herobg {
    background-image: url(../assets/images/background-image.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }

  /****************** Styling for Header.js ***********************/
  .head {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
  }

  .addresstext {
    color: #fff;
    font-family: "Epilogue", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .addresstexts {
    color: #000;
    font-family: "Epilogue", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .icondiv {
    display: flex;
  }

  .heading {
    padding-right: 35px;
  }
  .heading:last-child {
    padding-right: 0;
  }

  .navLinks {
    text-decoration: none;
    color: #fff;
    font-family: "Epilogue", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-right: 24px;
  }
  /****************** STYLING FOR HERO TEXT********************/

  .herotext {
    font-family: "Epilogue";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 54px;
    color: #fbb040;
    padding-top: 150px;
    text-transform: capitalize;
    letter-spacing: 2px;
  }

  .herosmall {
    font-family: "Epilogue";
    letter-spacing: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 54px;
    color: #fff;
  }

  /******************************* End of Styling Hero text***************/

  /*********************************** Dropdown style ***************************/

  .db_head {
    margin-top: 210px;
    display: flex;
    justify-content: space-between;
  }

  .db {
    font-size: 7px;
    padding-top: -10px;
  }

  .db_form {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .db_input {
    width: 24%;
    height: 40px;
    color: #000;
    border: 1px solid #bcbec0;
    border-radius: 0;
    margin-bottom: 16px;
    margin-right: 15px;
    padding: 7px;
  }

  .dateinput {
    width: 24%;
    height: 42px !important;
    color: #000 !important;
    border-radius: 0 !important;
    margin-bottom: 16px;
    margin-right: 15px;
    padding: 7px;
  }

  .dbbtn {
    width: 24%;
    height: 40px;
    color: #231f20;
    background-color: #fbb040;
    border: 2px solid #fbb040;
    border-radius: 0;
  }

  /*********************************** Dropdown style ***************************/

  .logoutdiv {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 768px) {
  .navbarScroll {
    display: none;
  }

  .gvlogo {
    width: 150px;
  }

  .addresstext {
    font-size: 14px;
    line-height: 18px;
  }

  .addresstexts {
    font-size: 14px;
    line-height: 18px;
  }

  .logo {
    width: 24px;
  }

  .head {
    padding: 15px 0;
  }

  .heading {
    padding-right: 25px;
  }

  .herotext {
    font-size: 35px;
    line-height: 45px;
    padding-top: 120px;
  }

  .herosmall {
    letter-spacing: 7px;
    font-size: 32px;
    line-height: 45px;
  }

  .db_head {
    display: none;
  }

  .phonebtn {
    margin-top: 170px;
    display: block;
  }

  .db_form {
    display: none;
  }

  .buttoncheck {
    width: 120px;
    margin-top: 8px;
  }
}

@media only screen and (max-width: 450px) {
  .gvlogo {
    width: 100px;
  }

  .addresstext {
    font-size: 10px;
    line-height: 12px;
    padding-right: 10px;
  }

  .addresstexts {
    font-size: 10px;
    line-height: 12px;
    padding-right: 10px;
  }

  .logo {
    width: 18px;
  }

  .head {
    padding: 10px 0;
  }

  .heading {
    padding-right: 15px;
  }

  .herotext {
    font-size: 28px;
    line-height: 35px;
    padding-top: 90px;
  }

  .herosmall {
    letter-spacing: 5px;
    font-size: 28px;
    line-height: 35px;
  }

  .phonebtn {
    margin-top: 130px;
  }

  .buttoncheck {
    width: 100px;
    margin-top: 2px;
  }
}
