.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signuptext {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 37px;
  color: #414042;
  padding-top: 100px;
  padding-bottom: 25px;
  text-align: center;
}

.detailstext {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #414042;
  padding-bottom: 25px;
  text-align: center;
}

.inputcheck {
  width: 550px;
  height: 40px;
  color: #000;
  border: 1px solid #bcbec0;
  border-radius: 0;
  margin-bottom: 16px;
}

.buttoncheck {
  width: 550px;
  height: 40px;
  color: #231f20;
  background-color: #fbb040;
  border: 2px solid #fbb040;
  border-radius: 0;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .signuptext {
    font-size: 30px;
    line-height: 31px;
    padding-top: 70px;
    padding-bottom: 20px;
  }

  .detailstext {
    padding-bottom: 20px;
  }

  .inputcheck {
    width: 450px;
    margin-bottom: 16px;
  }

  .buttoncheck {
    width: 450px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .signuptext {
    font-size: 25px;
    line-height: 26px;
    padding-top: 40px;
    padding-bottom: 15px;
  }

  .detailstext {
    font-size: 10px;
    line-height: 10px;
    padding-bottom: 15px;
  }

  .inputcheck {
    width: 350px;
    margin-bottom: 12px;
  }

  .buttoncheck {
    width: 350px;
  }
}

@media only screen and (max-width: 350px) {
  .inputcheck {
    width: 300px;
    margin-bottom: 10px;
  }

  .buttoncheck {
    width: 300px;
  }
}
